<template>
  <div class="CardApuracaoContainer">
    <v-row align="center" class="mx-0">
      <v-col>
        <aprovacao-massiva-apuracao
          class="ma-3"
          v-if="canAccessAprovMass"
          :disabled="!(selecionados && selecionados.length > 0)"
          :apuracoes="selecionados"
          @AprovacaoMassivaApuracao_sucesso="filtrar"
          @LimpaSelecionados_sucesso="limpaSelecionados">
        </aprovacao-massiva-apuracao>
      </v-col>
    </v-row>
    <v-data-table
      class="elevation-1"
      :headers="headers"
      :items="lista"
      v-model="selecionados"
      item-key="id"
      show-select
      :options.sync="pagination"
      :server-items-length="totalPage"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      :footer-props="{
        itemsPerPageOptions: [12, 24, 36, 48, 60, 72, 84],
      }">
      <template v-slot:item.id_acao="{ item }">
        {{ item.idAcao }}
      </template>
      <template v-slot:item.id_apuracao="{ item }">
        {{ item.idApuracao }}
      </template>
      <template v-slot:item.tipo_acao="{ item }">
        {{ item.tipoAcao }}
      </template>
      <template v-slot:item.tipo_verba="{ item }">
        {{ item.tipoVerba }}
      </template>
      <template v-slot:item.valor="{ item }">
        {{ getMoney(item.vlrConfApuracao) }}
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
            <v-btn icon
              v-if = item.justificativa
              v-on="on">
              <v-icon color="primary">info</v-icon>
            </v-btn>
          </template>
            <span>{{ item.justificativa }}</span>
          </v-tooltip>
      </template>
      <template v-slot:item.valor_original="{ item }">
        {{ getMoney(item.vlrConfApuracaoOriginal) }}
      </template>
      <template v-slot:item.foco_acao="{ item }">
        {{ item.focoAcao }}
      </template>
      <template v-slot:item.cliente_recebedor="{ item }">
        {{ item.clienteRecebedor }}
      </template>
      <template v-slot:item.status="{ item }">
        <status :status="item.status" :tooltip="item.status"></status>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Status from '@/produto/shared-components/Status';
import { generateComputed } from '@/produto/common/functions/roles-computed-generator';
import { listarApuracoesSuzano } from '@/common/resources/apuracao-suzano';
import { getMoney } from '@/produto/common/functions/helpers';
import AprovacaoMassivaApuracao from '@/spa/apuracao-acao/AprovacaoMassivaApuracao';

export default {
  name: 'ApuracaoEmLista',
  components: {
    AprovacaoMassivaApuracao,
    Status,
  },
  data() {
    return {
      workflowApuracaAcaoResource: this.$api.workflowApuracaoAcao(this.$resource),
      lista: [],
      selecionados: [],
      filtrosAplicados: {},
      pagination: {
        itemsPerPage: 12,
      },
      totalPage: 0,
      buscando: false,
      esperarDigitar: false,
      headers: [

        {
          text: this.$tc('label.cod_acao', 1), align: 'left', value: 'id_acao', sortable: true, width: '10%',
        },
        {
          text: this.$tc('label.cod_apuracao', 1), align: 'left', value: 'id_apuracao', sortable: true, width: '10%',
        },
        {
          text: this.$tc('label.tipo_acao', 1), align: 'left', value: 'tipo_acao', sortable: true, width: '15%',
        },
        {
          text: this.$tc('label.tipo_de_verba', 1), align: 'left', value: 'tipo_verba', sortable: true, width: '15%',
        },
        {
          text: this.$tc('label.valor_pagamento_calculado', 1), align: 'left', value: 'valor_original', sortable: true, width: '10%',
        },
        {
          text: this.$tc('label.valor_pagamento', 1), align: 'left', value: 'valor', sortable: true, width: '10%',
        },
        {
          text: this.$tc('label.foco_acao', 1), align: 'left', value: 'foco_acao', sortable: true, width: '15%',
        },
        {
          text: this.$tc('label.cliente_recebedor', 1), align: 'left', value: 'cliente_recebedor', sortable: true, width: '20%',
        },
        {
          text: this.$tc('label.status', 1), align: 'left', value: 'status', sortable: true, width: '10%',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('filtros', [
      'filtroApuracaoAcaoNivel2',
      'filtroApuracaoAcao',
    ]),
    ...generateComputed('APU_ACAO', [
      'canEdit',
    ]),
    canAccessAprovMass() {
      return true;
    },
  },
  watch: {
    filtroApuracaoAcao: {
      handler() {
        this.filtrosAplicados = this.filtroApuracaoAcao;
        this.filtrar();
      },
    },
    pagination: {
      handler() {
        if (this.filtroApuracaoAcao) {
          this.filtrar();
        }
      },
      deep: true,
    },
  },
  methods: {
    getMoney,
    ...mapActions('filtros', [
      'setFiltroApuracaoAcaoNivel2',
    ]),
    preencherFiltrosNivel2() {
      this.pagination.page = this.filtroApuracaoAcaoNivel2.pagina;
      this.pagination.itemsPerPage = this.filtroApuracaoAcaoNivel2.quantidatePorPagina;
    },
    limpaSelecionados() {
      this.selecionados = [];
    },
    filtrar() {
      if (this.esperarDigitar) return;
      this.esperarDigitar = true;
      setTimeout(() => {
        this.esperarDigitar = false;
        this.buscar();
      }, 5E2);
    },
    buscar() {
      this.consultar(listarApuracoesSuzano, (response) => {
        this.lista = this.verificarUsuarioAprovador(response.data.resposta);
        this.totalPage = response.data.quantidadeRegistrosPagina;
        this.preencherFiltrosNivel1Vuex();
        setTimeout(this.finalizandoBusca);
      });
    },
    consultar(consulta, sucesso) {
      if (this.buscando) return;
      this.buscando = true;

      const params = {
        ...this.filtrosAplicados,
        filtro: this.pesquisaCards,
        tamanhoPagina: this.pagination.itemsPerPage,
        numeroPagina: this.pagination.page,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
      };

      consulta(params, this.$resource)
        .then(sucesso)
        .catch((err) => {
          this.finalizandoBusca();
          this.$error(this, err);
        });
    },
    finalizandoBusca() {
      this.buscando = false;
    },
    preencherFiltrosNivel1Vuex() {
      const pagina = this.pagination.page;
      const quantidatePorPagina = this.pagination.itemsPerPage;

      this.setFiltroApuracaoAcaoNivel2({
        pagina,
        quantidatePorPagina,
      });
    },
    verificarUsuarioAprovador(apuracoes) {
      apuracoes.filter((el) => el.status === 'AGUARDANDO_APROVACAO').forEach((c) => {
        const idApuracao = c.id;
        this.workflowApuracaAcaoResource.aprovador({ idApuracao })
          .then((response) => {
            if (response.data.usuarioHabilitado) {
              c.isSelectable = true;
            } else {
              c.isSelectable = false;
            }
          }).catch((err) => {
            this.$error(this, err);
          });
      });
      return apuracoes;
    },
  },
  mounted() {
    if (this.filtroApuracaoAcaoNivel2) {
      this.preencherFiltrosNivel2();
    }
    if (this.filtroApuracaoAcao) {
      this.filtrosAplicados = this.filtroApuracaoAcao;
      this.filtrar();
    }
  },
};
</script>
