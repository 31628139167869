var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"CardApuracaoContainer"},[_c('v-row',{staticClass:"mx-0",attrs:{"align":"center"}},[_c('v-col',[(_vm.canAccessAprovMass)?_c('aprovacao-massiva-apuracao',{staticClass:"ma-3",attrs:{"disabled":!(_vm.selecionados && _vm.selecionados.length > 0),"apuracoes":_vm.selecionados},on:{"AprovacaoMassivaApuracao_sucesso":_vm.filtrar,"LimpaSelecionados_sucesso":_vm.limpaSelecionados}}):_vm._e()],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.lista,"item-key":"id","show-select":"","options":_vm.pagination,"server-items-length":_vm.totalPage,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
      itemsPerPageOptions: [12, 24, 36, 48, 60, 72, 84],
    }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.id_acao",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.idAcao)+" ")]}},{key:"item.id_apuracao",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.idApuracao)+" ")]}},{key:"item.tipo_acao",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.tipoAcao)+" ")]}},{key:"item.tipo_verba",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.tipoVerba)+" ")]}},{key:"item.valor",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getMoney(item.vlrConfApuracao))+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [(item.justificativa)?_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("info")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.justificativa))])])]}},{key:"item.valor_original",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getMoney(item.vlrConfApuracaoOriginal))+" ")]}},{key:"item.foco_acao",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.focoAcao)+" ")]}},{key:"item.cliente_recebedor",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.clienteRecebedor)+" ")]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('status',{attrs:{"status":item.status,"tooltip":item.status}})]}}]),model:{value:(_vm.selecionados),callback:function ($$v) {_vm.selecionados=$$v},expression:"selecionados"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }