import getBasePath from '@/produto/common/functions/api-resource';
import resourceBuilder from '@/produto/common/functions/metadados-resource-builder';

const basePath = getBasePath('apuracao_acao', 'suzano/apuracao/listagem');

const apuracaoActions = {};

export default (resource) => resource(`${basePath}`, {}, apuracaoActions);

export const listarApuracoesSuzano = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'em-lista', parametros).doGet();
